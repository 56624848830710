.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0;
    align-items: center;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 100;
}

.search-icon{
    display: block;
}

@media screen and (min-width: 786px) {

    .search-icon{
        display: none !important;
    }

}