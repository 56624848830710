.search {
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 70%;
    padding: 10px;
    border-radius: 50px;
    border: 2px solid var(--primary);
    position: relative;
}

.search-input {
    width: 100%;
    font-size: large;
    border: none;
    outline: none;
}

.search-dropdown {
    position: absolute;
    top: 105%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 450px;
    overflow-y: auto;
}

@media screen and (min-width: 786px) {
    .search {
        width: 50%;
        display: flex;
    }
}